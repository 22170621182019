import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import wlanService from './wlanService';

export default {
  getWlansRating(ctx) {
    ctx.$store.commit('toggleLoadingWlansRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();
    ctx.disableStatMode();
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/wlans`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const wlansRatingList = response.data.data.itemslist;

            const wlansIds = [];
            response.data.data.itemslist.forEach((wlan) => {
              if (!wlansIds.includes(wlan.id)) {
                wlansIds.push(wlan.id);
              }
            });
            wlanService.getSelectedWlans(ctx, wlansIds);
            ctx.$store.commit('setWlansRatingList', wlansRatingList);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingWlansRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingWlansRating');
        }
      );
  },

  getWlansRatingWithLocationFilter(ctx) {
    ctx.$store.commit('setWlansRatingList', false);
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }
    // if (ctx.filters.company !== 'All companys') {
    //   ctx.filters.location = 'All locations';
    //   ctx.filters.with_childs = false;
    //   withChilds = false;
    //   locationForQ = ctx.filters.company.locationId;
    //   // withChilds = false;
    // }

    ctx.$store.commit('toggleLoadingWlansRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();
    ctx.disableStatMode();
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/wlans`, {
        action: 'R',
        timebounds: { start, stop },
        location: locationForQ,
        with_childs: withChilds
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const wlansRatingList = response.data.data.itemslist;

            const wlansIds = [];
            response.data.data.itemslist.forEach((wlan) => {
              if (!wlansIds.includes(wlan.id)) {
                wlansIds.push(wlan.id);
              }
            });
            if (wlansIds.length) {
              wlanService.getSelectedWlans(ctx, wlansIds);
            }
            ctx.$store.commit('setWlansRatingList', wlansRatingList);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingWlansRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingWlansRating');
        }
      );
  },

  getWlanStat(ctx) {
    // Получаем статистику по конкретному Wlan зв определенный период времени
    ctx.$store.commit('toggleLoadingStats');
    if (!ctx.drPickerDateForStat.startDate || !ctx.drPickerDateForStat.endDate) {
      ctx.drPickerDateForStat.startDate = ctx.drPickerDateForRating.startDate;
      ctx.drPickerDateForStat.endDate = ctx.drPickerDateForRating.endDate;
    }
    const start = moment(ctx.drPickerDateForStat.startDate).unix();
    const stop = moment(ctx.drPickerDateForStat.endDate).unix();

    const periodStart = ctx.drPickerDateForStat.startDate / 1000;
    let periodStop = ctx.drPickerDateForStat.endDate / 1000;
    if (periodStop * 1000 > Date.now()) {
      periodStop = Date.now() / 1000;
    }
    const minXAxis = new Date(periodStart * 1000);
    const maxXAxis = new Date(periodStop * 1000);

    ctx.txRxChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );
    ctx.txRxRateChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );
    ctx.txRxRateChartPerUser.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChartPerUser.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );
    ctx.clientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.clientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );
    ctx.uniqueClientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.uniqueClientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    let apiLink;
    let statQuery;
    apiLink = '/api/stat/timeline/wlans';
    statQuery = {
      action: 'R',
      ids: [ctx.wlanSelectedId],
      timebounds: { start, stop }
    };

    Vue.axios.post(`${window.RESTAPI || ''}${apiLink}`, statQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.currentWlanStats = response.data.data.itemslist;
          // Обнуляю данные графиков
          ctx.refreshCharts();

          if (ctx.currentWlanStats.length === 0) {
            ctx.$store.commit('toggleLoadingStats');
            return;
          }
          // Сортирую массив статистики по времени
          ctx.$store.commit('toggleLoadingStats');
          const sortedWlanStats = ctx.currentWlanStats.sort((curr, prev) => {
            if (curr.timestamp > prev.timestamp) {
              return 1;
            }
            if (curr.timestamp < prev.timestamp) {
              return -1;
            }
            return 0;
          });
          // Формирую данные для HC
          sortedWlanStats.forEach((item) => {
            if (item.timestamp == 0) {
              return;
            }
            const time = new Date(item.timestamp * 1000);
            if (item.cpu_load > 1) {
              item.cpu_load = 1;
            }

            // Data for TxRx
            ctx.txRxChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat(
                (
                  parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
                ).toFixed(2)
              )
            ]);

            // Data for Rate TxRx
            ctx.txRxRateChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4))
            ]);
            ctx.txRxRateChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            ]);
            ctx.txRxRateChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4)) +
                parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            ]);

            ctx.txRxRateChartPerUser.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(4)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(4)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ?
                parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(4)) +
                  parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(4)) :
                0
            ]);

            // Data for clients
            ctx.clientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients
            ]);

            // Data for unique clients
            ctx.uniqueClientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.unique_clients
            ]);

            // Data for CPU & Memory Load
            let cpuPercent = item.cpu_load * 100;
            cpuPercent = Math.round(cpuPercent * 100) / 100;
            let memPercent = ((item.memory_total - item.memory_free) * 100) / item.memory_total;
            memPercent = Math.round(memPercent * 100) / 100;
            const arrayCPU = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              cpuPercent
            ];
            const arrayMemory = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              memPercent
            ];
            ctx.cpuCharts.series[0].data.push(arrayCPU);
            ctx.memoryCharts.series[0].data.push(arrayMemory);
          });
        } else if (response.data.status === 'error') {
          ctx.refreshCharts();
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingStats');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingStats');
      }
    );
  }
};
